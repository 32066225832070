import ThreeSixtyPage from './../360'
import React from 'react'
import { connect } from 'react-redux'

function ThreeSixtyEn({ translations, lang }) {
    return (
        <ThreeSixtyPage translations={translations} lang={lang}/>
    )
}

const mapStateToProps = state => ({
    translations: state.preferences.translations,
    lang: state.preferences.lang,
    serverCall: state.preferences.serverCall
})

export default connect(mapStateToProps)(ThreeSixtyEn)
